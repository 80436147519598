import axios from 'axios';
import utils from '../utils.js'

export default {
	async createAudioNoticeboard(data){
		try{
			const response = await axios.post(`/api/audio-noticeboard/`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			});
			return response.data;
		}catch(error){
			return utils.getError(error)
		}
	},
	async getAudioNoticeboard(id){
		try{
			const response = await axios.get(`/api/audio-noticeboard/${id}/`);
			return response
		}catch(error){
			return utils.getError(error)
		}
	},
	async updateAudioNoticeboard(id, data){
		try{
			const response = await axios.put(`/api/audio-noticeboard/${id}/`, data);
			return response
		}catch(error){
			return utils.getError(error)
		}
	}
}
