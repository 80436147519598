<template>
	<div class="router-view-wrapper">
		<SuccessErrorMessage :message="message" />
		<q-card>
			<MessageFormNoticeboard :called_from_noticeboard='true' />
			<q-card-actions align="right" class="text-primary">
				<q-btn
					outline
					color="primary"
					class="q-mr-sm"
					icon-right="save"
					label="Save"
					style="width: 110px;"
					@click="createNoticeboard()"
				/>
				<q-btn
					outline
					color="primary"
					icon-right="cancel"
					label="Cancel"
					style="width: 110px;"
					:to="{ name: 'Audio Noticeboard'}"
				/>
			</q-card-actions>
		</q-card>
	</div>
</template>

<script>
import NoticeboardApi from '@/services/api/AudioNoticeboard.js';
import MessageFormNoticeboard from '@/components/message_forms/audio_noticeboard_tab/MessageFormNoticeboard.vue';
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";
import message_forms_utils from '@/utils/message_forms.js';

import { mapGetters, mapActions } from 'vuex';

export default {
	name: "AudioNoticeboardCreateOrEdit",
	meta: {
		title: 'Alert Cascade - Audio Noticeboard'
	},
	components: {
		MessageFormNoticeboard,
		SuccessErrorMessage,
	},
	computed: {
		...mapGetters([
			'noticeboard_number',
			'noticeboard_language',
			'noticeboard_name',
			'noticeboard_message',
			'default_noticeboard_message_voice',
			'current_CG',
		])
	},
	data() {
		return {
			message: {},
			media_library_feature: false,
			is_saved: false,
		};
	},
	methods: {
		...mapActions([
			'updateNoticeboardName',
			'updateNoticeboardNumber',
			'updateNotice',
			'updateNoticeboardMessage',
			'updateNoticeboardLanguage',
			'updateDefaultNoticeboardMessageVoice',
			'updateNoticeboardEdit',
			'resetFields',
			'updateIsAudioNoticeboardRunning',
			'set_active_item',
		]),
		async createNoticeboard() {
			let data = this.createNoticeboardData();
			try {
				if (!this.$route.params.id) {
					this.updateIsAudioNoticeboardRunning(true);
					const response = await NoticeboardApi.createAudioNoticeboard(data);
					this.message = {
						'type': 'success',
						'text': 'The audio noticeboard was successfully created.'
					};
					this.is_saved = true;
					this.updateIsAudioNoticeboardRunning(false);
				} else {
					this.updateIsAudioNoticeboardRunning(true);

					await NoticeboardApi.updateAudioNoticeboard(this.$route.params.id, data);
					this.updateIsAudioNoticeboardRunning(false);

					this.message = {
						'type': 'success',
						'text': 'The audio noticeboard was successfully updated.'
					};
				}
			} catch (error) {
				this.updateIsAudioNoticeboardRunning(false);
				this.message = {
					'type': 'error',
					'text': error.data
				};
			}
			this.$router.push({ name: 'Audio Noticeboard'})
		},
		createNoticeboardData() {
			let noticeboard = {};
			let customer_group_settings = JSON.parse(this.current_CG.customer_group.settings);
			noticeboard.name = this.noticeboard_name;
			noticeboard.phone_number = this.noticeboard_number;
			noticeboard.settings = {};
			noticeboard.settings.message_type = 'noticeboard_tts';
			noticeboard.message = this.noticeboard_message;
			noticeboard.message_type = 'noticeboard_tts';
			if (!this.default_noticeboard_message_voice) {
				if (this.noticeboard_language != undefined) {
					noticeboard.settings.tts_voice = this.noticeboard_language.name;
					noticeboard.noticeboard_tts_voice = this.noticeboard_language.name;
				} else {
					noticeboard.settings.tts_voice = null;
					noticeboard.noticeboard_tts_voice = null;
				}
			}
			else {
				if (customer_group_settings.default_tts_voice) {
					noticeboard.settings.tts_voice = customer_group_settings.default_tts_voice.name;
					noticeboard.noticeboard_tts_voice = customer_group_settings.default_tts_voice.name;
				}else{
					noticeboard.settings.tts_voice = 'Amy'
					noticeboard.noticeboard_tts_voice = 'Amy'
				}
			}
			return noticeboard;
		},
		async getNoticeboardData(noticeboard_data) {
			this.updateNoticeboardName(noticeboard_data.data.name);
			this.updateNoticeboardNumber(noticeboard_data.data.phone_number);
			let noticeboard_settings = JSON.parse(noticeboard_data.data.settings);
			let customer_group_settings = JSON.parse(this.current_CG.customer_group.settings);
			this.updateNoticeboardMessage(noticeboard_data.data.message);
			if (noticeboard_settings.tts_voice) {
				if (customer_group_settings.default_tts_voice) {
					if (noticeboard_settings.tts_voice == customer_group_settings.default_tts_voice.name) {
						this.updateDefaultNoticeboardMessageVoice(true);
					} else {
						this.updateDefaultNoticeboardMessageVoice(false);
					}
					if (!this.$store.getters.default_noticeboard_message_voice && noticeboard_settings.tts_voice) {
						for (let item of this.people) {
							if (item.name == noticeboard_settings.tts_voice) {
								this.updateNoticeboardLanguage(item);
								break;
							}
						}
					}
				} else {
					this.updateDefaultNoticeboardMessageVoice(false);
					for (let item of this.people) {
						if (item.name == noticeboard_settings.tts_voice) {
							this.updateNoticeboardLanguage(item);
							break;
						}
					}
				}
			} else {
				noticeboard.settings.tts_voice = 'Amy';
				noticeboard.noticeboard_tts_voice = 'Amy';
			}
		},
	},
	async created() {
		this.people = message_forms_utils.default_people();
		this.media_library_feature = this.current_CG.root_customer_group_permissions.includes("Media library");
		if (this.$route.params.id) {
			this.updateNoticeboardEdit(true);
			const response = await NoticeboardApi.getAudioNoticeboard(this.$route.params.id);
			this.getNoticeboardData(response);
		} else {
			this.resetFields();
		}
	},
	watch: {
		is_saved: function (val) {
			this.updateNoticeboardEdit(true);
		}
	},
}
</script>

<style lang="scss" scoped>
	.router-view-wrapper {
		padding: 1.5rem 1.5rem;
		background-color: #fff;
		@media (max-width: 768px) {
			padding: 1rem 0.5rem;
	}
}
</style>
<style lang='scss'>
	.data-wrapper {
		text-align: left;
		border-radius: 0.375rem;
		margin-bottom: 1rem;
		width: 100%;
		background: $grey-1;
		border: 1px solid $grey-4;
	}
</style>
